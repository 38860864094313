import React, { FC, useEffect, useRef, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import defaultProfileIcon from "../../assets/img/profile.svg";
import Input from "../../reusable/Input";
import { Anchor, InputGroup } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { LogoutUser } from "../../redux/slice/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import logoimg from "../../assets/img/Racklogo.svg";
import smallLogoImg from "../../assets/img/small_logo.svg";
import {
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import { fetchNotification } from "../../redux/slice/Notification";
import Notification from "../../reusable/Notification";
import { set } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchNotificationCount } from "../../redux/slice/Notification/NotificationCount";
import { fetchRemainingCreditPoints } from "../../redux/slice/Subscription/RemainingCreditPoints";
import decryptData from "../../reusable/decryptData";
import { fetchUser } from "../../redux/slice/User";
import { DataServer } from "../../config.axios";

interface customToggleProps {
  children: JSX.Element;
  onClick: Function;
}

const CustomToggle: FC<customToggleProps> = React.forwardRef(
  ({ children, onClick }, ref) => (
    <a
      href=""
      //ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  )
);

interface customMenuProps {
  children: JSX.Element;
  onClick: Function;
  style: Object;
  className: string;
  //labeledBy: string;
  "aria-labelledby": string;
}

const CustomMenu: FC<customMenuProps> = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");


    return (
      <div
        //ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter((child) => !value || "")}
        </ul>
      </div>
    );
  }
);

interface headerProps {
  search?: boolean;
}

const Header: FC<headerProps> = ({ search = false }) => {
  const userResponse = useAppSelector((state) => state.user);
  const { user } = userResponse;
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const ActivePage = useLocation();
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const loginUserData = decryptData();

  const userId = loginUserData?.data?.id;
  const role_id = loginUserData?.data?.role_id;
  const tpi_firm_id = loginUserData?.tpi_firm_id;
  const [showNotification, setShowNotification] = useState(false);
  const [showNotificationSpan, setShowNotificationSpan] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);

  const wrapperRef = useRef(null);

  const handleOnClick = () => {
    dispatch(LogoutUser(navigation));
  };

  useEffect(() => {
    if(userId){
      DataServer.post(
        `api/editUser`, {
          user_id: userId
        }).then((response) => {
        if (response.status === 200) {
          setFirstName(response?.data?.data?.first_name)
          setLastName(response?.data?.data?.last_name)
        }
      })
    }
  }, [userId]);

  useEffect(() => {
    if(userId){
      dispatch(fetchNotificationCount({ apiParam: {loginUserId: userId}}));
    }    
  }, [userId]);

  useEffect(() => {
    if ((role_id === 9 || role_id === 11) && tpi_firm_id) {
      dispatch(fetchRemainingCreditPoints({ apiParam: { tpi_firm_id: tpi_firm_id } }))
    }
  }, [role_id, tpi_firm_id]);

  const notification = useAppSelector((state) => state.notification);
  const notificationcount = useAppSelector((state) => state.notificationcount);
  const remainingcreditpoints = useAppSelector((state) => state.remainingcreditpoints);
  const users = useAppSelector((state) => state.users);

  const notificationAction = () => {
    dispatch(fetchNotification({ apiParam: {loginUserId: userId}}));
    setShowNotification(!showNotification);
    setShowNotificationSpan(false);
  }

  return (
    <Navbar bg="light" expand="lg" className="header">
      <div>
        {/* <ul className="navbar-nav align-items-center">
          {/* <li style={{width: "250px"}}>
            {/* <div className="logo_div">
              <img src={logoimg} alt="Logo" className="full_logo" />
              <img src={smallLogoImg} alt="Logo" className="small_logo" />
            </div>
          </li>
          <li className="nav-item dropdown brand-name">
            {/* <h2 className="nav_heading">RAMS by iNODE</h2>
            {/* <img src={logoimg} alt=""/>
            {/* <div>RACK AUDIT</div>
            <span>We Belive in Smart Solution's</span>
          </li>
        </ul> */}
      </div>
      <div className="d-flex align-items-center">
        {search && (
          <>
            <InputGroup className="search-filed">
              <InputGroup.Text>@</InputGroup.Text>
              <Input className="" value="" onChange={() => { }} />
            </InputGroup>
            <div className="line" />
          </>
        )}

        {
          role_id === 9 || role_id === 11 ?
            <div className="d-flex align-items-center available_credit">
              <h6>Credits Remaining</h6>
              <span>
                {remainingcreditpoints?.RemainingCreditPoints?.data && 
                remainingcreditpoints?.RemainingCreditPoints?.data["Available Credits"] !== undefined
                ? remainingcreditpoints?.RemainingCreditPoints?.data["Available Credits"] 
                : 0}
              </span>
            </div> : ""
        }

        <div style={{paddingRight: "10px", paddingLeft: "10px"}}>
          <Dropdown className="notification_dropdown">
            <Dropdown.Toggle className="d-flex align-items-center p-0" id="dropdown-basic" key={"down-centered"}>
              <div className="p-2" onClick={notificationAction}><FontAwesomeIcon icon={faBell}  /></div>
              {/* <span className="notificationCount">{notificationCount}</span> */}
              {notificationcount?.notificationcount?.data?.notification_count > 0 ? <span className="notificationCount">{notificationcount?.notificationcount?.data?.notification_count}</span> : ""}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item href=""> */}
              <Notification NotificationData={notification?.notification?.data}></Notification>
              {/* </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="">
          <Dropdown className="header_dropdown">
            <Dropdown.Toggle className="d-flex align-items-center" id="dropdown-basic" key={"down-centered"} >
              <div className="d-flex px-2">
                <img src={defaultProfileIcon} alt="Profile Image" width={40} />
                <div className="d-flax px-2">
                  <div className="username">{FirstName} {LastName}</div>
                  <div className="role">{user.role}</div>
                </div>
              </div>
              <FontAwesomeIcon icon={faEllipsisV} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                href=""
                as={Anchor}
                onClick={() => navigation(`/profile-setting`, { state: {pagetype: "profileSetting", userId: userId} })}
                className={ActivePage?.pathname?.includes("/profile-setting") ? "active" : ""}
              >
                Profile Settings
              </Dropdown.Item>
              {
                role_id === 4 || role_id === 5 ?
                <Dropdown.Item
                  href=""
                  as={Anchor}
                  onClick={() => navigation(`/warehouse-details`)}
                  className={ActivePage?.pathname?.includes("warehouse-details") ? "active" : ""}
                >
                  warehouse Details
                </Dropdown.Item> : ""
              }
              <Dropdown.Item
                href="/change-password"
                className={ActivePage?.pathname?.includes("/change-password") ? "active" : ""}
                as={Anchor}
              >
                Change Password
              </Dropdown.Item>
              <Dropdown.Item href="" onClick={handleOnClick} as={Anchor}>
                Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {/* {
        showNotification ? <Notification NotificationData={notification?.notification?.data} wrapperRef={wrapperRef}></Notification> : ""
      } */}
    </Navbar>
  );
};

export default Header;
