import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface TpiScheduleDataState {
  TpiScheduleData: {
    load: boolean;
    data: [];
  };
}

export const TpiScheduleDataSlice = createSlice({
  name: "TpiScheduleData",
  initialState: {
    TpiScheduleData: {
      load: false,
      data: [],
    },
  } as TpiScheduleDataState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchTpiScheduleData.pending, (state: TpiScheduleDataState) => {
        state.TpiScheduleData.load = true;
      })
      .addCase(fetchTpiScheduleData.fulfilled, (state: TpiScheduleDataState, action: any) => {
        state.TpiScheduleData.data = action.payload;
        state.TpiScheduleData.load = false;
      })
      .addCase(fetchTpiScheduleData.rejected, (state: TpiScheduleDataState) => {
        state.TpiScheduleData.load = false;
      });
  },
});

export default TpiScheduleDataSlice.reducer;

export const fetchTpiScheduleData = createAsyncThunk(
  "TpiScheduleData/fetchTpiScheduleData",
  async (param: any, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getTpiInspectionScheduleData", {
        tpi_firm_id: param.apiParam.tpi_firm_id
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      // return error;
    }
  }
);
