import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface ProjectCountState {
  ProjectCount: {
    load: boolean;
    data: [];
  };
}

export const ProjectCountSlice = createSlice({
  name: "ProjectCount",
  initialState: {
    ProjectCount: {
      load: false,
      data: [],
    },
  } as ProjectCountState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchProjectCount.pending, (state: ProjectCountState) => {
        state.ProjectCount.load = true;
      })
      .addCase(fetchProjectCount.fulfilled, (state: ProjectCountState, action: any) => {
        state.ProjectCount.data = action.payload;
        state.ProjectCount.load = false;
      })
      .addCase(fetchProjectCount.rejected, (state: ProjectCountState) => {
        state.ProjectCount.load = false;
      });
  },
});

export default ProjectCountSlice.reducer;

export const fetchProjectCount = createAsyncThunk(
  "ProjectCount/fetchProjectCount",
  async (param: any, thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getProjectCount", {
        tpi_firm_id: param.apiParam.tpi_firm_id
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      return error;
    }
  }
);
