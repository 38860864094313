import { DataServer } from "../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";

interface TpiGraphDataState {
  TpiGraphData: {
    load: boolean;
    data: [];
  };
}

export const TpiGraphDataSlice = createSlice({
  name: "TpiGraphData",
  initialState: {
    TpiGraphData: {
      load: false,
      data: [],
    },
  } as TpiGraphDataState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchTpiGraphData.pending, (state: TpiGraphDataState) => {
        state.TpiGraphData.load = true;
      })
      .addCase(fetchTpiGraphData.fulfilled, (state: TpiGraphDataState, action: any) => {
        state.TpiGraphData.data = action.payload;
        state.TpiGraphData.load = false;
      })
      .addCase(fetchTpiGraphData.rejected, (state: TpiGraphDataState) => {
        state.TpiGraphData.load = false;
      });
  },
});

export default TpiGraphDataSlice.reducer;

export const fetchTpiGraphData = createAsyncThunk(
  "TpiGraphData/fetchTpiGraphData",
  async (param: { apiParam: {year: any, tpi_firm_id: any} },thunkAPI) => {
    try {
      // thunkAPI.dispatch(toggleLoading());
      const { data } = await DataServer.post("api/getProjectOverviewData", {
        tpi_firm_id: param.apiParam.tpi_firm_id,
        year: param.apiParam.year
      });
      // thunkAPI.dispatch(toggleLoading());
      return data.data;
    } catch (error) {
      // thunkAPI.dispatch(toggleLoading());
      return error;
    }
  }
);
