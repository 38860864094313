import { DataServer } from "../../../../config.axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../../LoadingSlice";
import { activateSweetAlert, deActivateSweetAlert } from "../../SweetAlert";

interface UpdateEmailNotificationUserDataState {
    updateemailnotificationuserdata: {
        load: boolean;
        data: [];
        error: [];
    };
}

export const UpdateNotificationSlice = createSlice({
    name: "updateemailnotificationuserdata",
    initialState: {
        updateemailnotificationuserdata: {
            load: false,
            data: [],
            error: [],
        },
    } as UpdateEmailNotificationUserDataState,
    reducers: {
        NotificationUserError: (state: UpdateEmailNotificationUserDataState, action: any) => {
            state.updateemailnotificationuserdata.error = action.payload;
        },
    },
});

export const { NotificationUserError } = UpdateNotificationSlice.actions;
export default UpdateNotificationSlice.reducer;

export const editNotificationUser = createAsyncThunk(
    "notificationusers/editNotificationUser",
    async (param: { apiParam: any, navigation: any }, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleLoading());
            const { data } = await DataServer.post(
                "api/PostAddNotificationUser",
                param.apiParam
            );

            thunkAPI.dispatch(toggleLoading());

            thunkAPI.dispatch(
                activateSweetAlert({
                    show: true,
                    type: "success",
                    title: "Success!",
                    confirmBtnText: 'Done',
                    message: data.message,
                    onConfirm: () => {
                        handleonConfirm()
                    },
                })
            );

            const handleonConfirm = () => {
                param.navigation("/email-notification");
                thunkAPI.dispatch(deActivateSweetAlert());
            };
            return data.data;
        } catch (error) {
            thunkAPI.dispatch(toggleLoading());
            // thunkAPI.dispatch(ErrorMessage(error));
            thunkAPI.dispatch(UpdateNotificationSlice.actions.NotificationUserError(error));
            // Return the error object
            return thunkAPI.rejectWithValue(error);
            // return error;
        }
    }
);
