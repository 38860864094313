import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../../sensorconfig.axios";

interface TodaySpeedStatisticsState {
  TodaySpeedStatistics: {
    load: boolean;
    data: [];
  };
}

export const TodaySpeedStatisticsSlice = createSlice({
  name: "TodaySpeedStatistics",
  initialState: {
    TodaySpeedStatistics: {
      load: false,
      data: [],
    },
  } as TodaySpeedStatisticsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTodaySpeedStatistics.pending, (state) => {
        state.TodaySpeedStatistics.load = true;
      })
      .addCase(getTodaySpeedStatistics.fulfilled, (state, action) => {
        state.TodaySpeedStatistics.data = action.payload;
        state.TodaySpeedStatistics.load = false;
      })
      .addCase(getTodaySpeedStatistics.rejected, (state) => {
        state.TodaySpeedStatistics.load = false;
      });
  },
});

export default TodaySpeedStatisticsSlice.reducer;

export const getTodaySpeedStatistics = createAsyncThunk(
  "TodaySpeedStatistics/getTodaySpeedStatistics",
  async (param: any, thunkAPI) => {
    try {
      var data: any; 
      if(param.apiParam.operator_id === "all"){
        data = await DataServer.get("management/mheSummary/v1/getTodaySpeedStatistics?warehouse_id=" + param.apiParam.warehouse_id + "&mhe_id=" + param.apiParam.mhe_id + "&duration=" + param.apiParam.duration);
      } else {
        data = await DataServer.get("management/mheSummary/v1/getTodaySpeedStatistics?warehouse_id=" + param.apiParam.warehouse_id + "&mhe_id=" + param.apiParam.mhe_id + "&duration=" + param.apiParam.duration + "&operator_id=" + param.apiParam.operator_id);
      }
      return data.data;
    } catch (error) {
      return error;
    }
  }
);
