import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataServer } from "../../../../sensorconfig.axios";

interface MheSummaryState {
  mhesummary: {
    load: boolean;
    data: [];
  };
}

export const MheSummarySlice = createSlice({
  name: "mhesummary",
  initialState: {
    mhesummary: {
      load: false,
      data: [],
    },
  } as MheSummaryState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMHESummaryList.pending, (state) => {
        state.mhesummary.load = true;
      })
      .addCase(getMHESummaryList.fulfilled, (state, action) => {
        state.mhesummary.data = action.payload;
        state.mhesummary.load = false;
      })
      .addCase(getMHESummaryList.rejected, (state) => {
        state.mhesummary.load = false;
      });
  },
});

export default MheSummarySlice.reducer;

export const getMHESummaryList = createAsyncThunk(
  "mhesummary/getMHESummaryList",
  async (param: any, thunkAPI) => {
    console.log("param", param)
    try {
      const { data, status } = await DataServer.get(
        "management/v1/getMHESummaryList?per_page=" + param.apiParam.per_page + "&current_page=" + param.apiParam.current_page + "&company_id=" + param.apiParam.company_id+ "&warehouse_id=" + param.apiParam.warehouse_id + "&search=" + param.apiParam.search
      );
      if (status !== 200) throw "Login Error";
      return data.data;
    } catch (error) {
      return error;
    }
  }
);
