import { combineReducers } from "@reduxjs/toolkit";
import Alert from "./slice/Alert";
import Auth from "./slice/Auth";
import Cities from "./slice/Cities";
import Countries from "./slice/Countries";
import User from "./slice/User";
// import reportedIssue from "./slice/ReportedIssue";
import inspectorList from "./slice/InspectorList";
import Cycle from "./slice/InspectionScheduleCalculations"
import ScheduleData from "./slice/InspectionScheduleCalculations/FetchScheduleData"
import Roles from "./slice/Role"
import Issues from "./slice/Issues"
import company from "./slice/Company"
import quotation from "./slice/Quotation"
import licence from "./slice/Licence"
import quotelicence from "./slice/LicenceByQuotation"
import SweetAlert from "./slice/SweetAlert";
import issuedetails from "./slice/IssueDetails";
import Dashboard from "./slice/Dashboard";
import RackHealth from "./slice/Dashboard/RackHealth";
import InspectorList from "./slice/Dashboard/InspectorList";
import GraphInspectorList from "./slice/Dashboard/GraphInspectorList";
import GraphData from "./slice/Dashboard/GraphData";
import DetailedGraphData from "./slice/Dashboard/DetailedGraphData";
import RackCount from "./slice/RackCount";
import ReportIssue from "./slice/ReportIssue";
import Inspection from "./slice/Inspection";
import CompletedInspectionDetails from "./slice/Inspection/CompletedInspectionDetails";
import TpiFirmList from "./slice/PRSESTPI/TpiFirmList";
import TPInspectionSummary from "./slice/Inspection/TPInspectionSummary";
import TpiFirmDetails from "./slice/PRSESTPI/TpiFirmDetails";
import QRCodes from "./slice/QRCodes";
import RackInspectionStatistics from "./slice/Dashboard/RackInspectionStatistics";
import RackHealthCount from "./slice/RackHealth/RackHealthCount";
import ProjectList from "./slice/TPIProject/ProjectList";
import LayoutList from "./slice/RackHealth/LayoutList";
import MasterLayout from "./slice/RackHealth/MasterLayout";
import InspectionElementCount from "./slice/InspectionDetails/ElementCount";
import WarehouseDetails from "./slice/InspectionDetails/WarehouseDetails";
import InspectionStatistics from "./slice/InspectionDetails/InspectionStatistics";
import RackRun from "./slice/RackHealth/RackRun";
import NodesData from "./slice/RackHealth/NodesData";
import BeamData from "./slice/RackHealth/BeamData";
import Report from "./slice/Report";
import InspectorDetails from "./slice/Report/InspectorDetails";
import InspectionSetting from "./slice/InspectionSetting";
import loadingReducer from "../redux/slice/LoadingSlice";
import ComapnyDetails from "./slice/Report/ComapnyDetails";
import elementcount from "./slice/Report/ElementCount";
import ReportReportedIssue from "./slice/Report/ReportedIssue";
import IssueImages from "./slice/Issues/IssueImages";
import DetailedInspectorList from "./slice/Dashboard/DetailedInspectorList";
import ErrorMessage from "./slice/ErrorHandling";
import CloseIssue from "./slice/Issues/CloseIssue";
import TestReading from "./slice/Issues/TestReading";
import ElementSummary from "./slice/MasterList/ElementSummary";
import ObservationList from "./slice/Issues/ObservationList";
import SubIssueList from "./slice/MasterList/SubIssueList";
import SyncTpiDataToPrses from "./slice/SyncTpiDataToPrses";
import SyncReportedIssues from "./slice/SyncTpiDataToPrses/SyncReportedIssues";
import RackHealthGraphData from "./slice/RackHealth/RackHealthGraphData";
import TpiActionRequired from "./slice/Issues/TpiActionRequired";
import PrsesElementCount from "./slice/InspectionStatisticsPrses/PrsesElementCount";
import SyncRackHealthCount from "./slice/InspectionStatisticsPrses/SyncRackHealthCount";
import SyncInspectionCount from "./slice/InspectionStatisticsPrses/SyncInspectionCount";
import SyncWarehouseData from "./slice/InspectionStatisticsPrses/SyncWarehouseData";
import ProjectCount from "./slice/TpiDashboard/ProjectCount";
import TpiScheduleData from "./slice/TpiDashboard/TpiScheduleData";
import TpiGraphData from "./slice/TpiDashboard/TpiGraphData";
import ProjectStatus from "./slice/TpiDashboard/ProjectStatus";
import PreviousAction from "./slice/Issues/FetchPreviousAction/PreviousAction";
import WarehouseData from "./slice/TPIReport/WarehouseData";
import RackSystemData from "./slice/TPIReport/RackSystemData";
import TechnicalInspectionData from "./slice/TPIReport/TechnicalInspectionData";
import RackWiseHealthData from "./slice/TPIReport/RackWiseHealthData";
import FrameWiseHealthData from "./slice/TPIReport/FrameWiseHealthData";
import TpiReportedIssues from "./slice/TPIReport/TpiReportedIssues";
import TestingTestReading from "./slice/TPIReport/TestingTestReading";
import TpiReportedIssueDetails from "./slice/TPIReport/TpiReportedIssueDetails";
import LevelStatistics from "./slice/RackHealth/LevelStatistics";
import TpiLayoutList from "./slice/TPIReport/TpiLayoutList";
import Conclusion from "./slice/TPIReport/Conclusion";
import TpiReportElementCount from "./slice/TPIReport/TpiReportElementCount";
import TpiGeneralizedIssues from "./slice/TPIReport/TpiGeneralizedIssues";
import SyncInspectionStatistics from "./slice/InspectionStatisticsPrses/SyncInspectionStatistics";
import TpiConclusionData from "./slice/TPIReport/TpiConclusionData";
import TpiVerticalSummary from "./slice/TPIReport/TpiVerticalSummary";
import ReportedIssue from "./slice/Issues/ReportedIssue";
import ComparedReportedIssues from "./slice/Issues/ComparedReportedIssues";
import StructuralImages from "./slice/TPIReport/StructuralImages";
import StructuralExcel from "./slice/TPIReport/StructuralExcel";
import SetupVendor from "./slice/SetupVendor";
import ChangePassword from "./slice/ChangePassword";
import FrameWiseData from "./slice/Report/FrameWiseData";
import IssueList from "./slice/MasterList/IssueList";
import IssueChecklist from "./slice/MasterList/IssueChecklist";
import TestingList from "./slice/Testing/TestingList";
import TestingSummary from "./slice/Testing/TestingSummary";
import TestingDetails from "./slice/Testing/TestingDetails";
import IssueCounts from "./slice/Dashboard/IssueCounts";
import Notification from "./slice/Notification";
import ImpactList from "./slice/ImpactDetection/ImpactList";
import TpiElementWiseDamages from "./slice/TPIReport/TpiElementWiseDamages";
import ExecutionSummary from "./slice/TPIReport/ExecutionSummary";
import Compliance from "./slice/TPIReport/Compliance";
import StabilityCertificate from "./slice/TPIReport/StabilityCertificate";
import MaintainanceStrategy from "./slice/TPIReport/MaintainanceStrategy";
import RiskAssessment from "./slice/TPIReport/RiskAssessment";
import Annexure from "./slice/TPIReport/Annexure";
import InventorySummary from "./slice/TPIReport/InventorySummary";
import fetchLayoutList from "./slice/PalleteManagment/fetchLayoutList";
import RackList from "./slice/PalleteManagment/RackList";
import TimeZone from "./slice/TimeZone";
import PlansList from "./slice/Subscription/PlansList";
import Coupon from "./slice/Coupon";
import CouponDetails from "./slice/Coupon/CouponDetails";
import CouponOwnedBy from "./slice/Coupon/CouponOwnedBy";
import CouponWarehouseList from "./slice/Coupon/CouponWarehouseList";
import AddPackage from "./slice/Subscription/AddPackage";
import OverloadingData from "./slice/PalleteManagment/OveloadingData";
import HealthParameterList from "./slice/MasterList/HealthParameterList";
import UpdateRiskAssessmentChart from "./slice/MasterList/UpdateRiskAssessmentChart";
import EditHealthParameter from "./slice/MasterList/EditHealthParameter";
import CustomRiskAssessmentChartData from "./slice/MasterList/CustomRiskAssessmentChartData";
import EffectOfEvent from "./slice/MasterList/EffectOfEvent";
import fetchIssueDiagnoseParameterData from "./slice/MasterList/fetchIssueDiagnoseParameterData";
import NotificationCount from "./slice/Notification/NotificationCount";
import SubscriptionDetails from "./slice/CreditSystem/SubscriptionDetails";
import CreditSystemStatistics from "./slice/CreditSystem/CreditSystemStatistics";
import CreditSystemTransactions from "./slice/CreditSystem/CreditSystemTransactions";
import TpiCompanyLogo from "./slice/TPIReport/TpiCompanyLogo";
import NoSeverityCount from "./slice/MasterList/NoSeverityCount";
import AllCreditPoints from "./slice/Subscription/AllCreditPoints";
import RemainingCreditPoints from "./slice/Subscription/RemainingCreditPoints";
import ManagementUserSummary from "./slice/Company/ManagementUserSummary";
import WarehouseList from "./slice/Company/WarehouseList";
import UserAssignedWarehouseList from "./slice/Company/UserAssignedWarehouseList";
import ManagementUserDashboard from "./slice/Dashboard/ManagementUserDashboard";
import AddUpdateNotificationUser from "./slice/Notification/AddUpdateNotificationUser";
import NotificationUserData from "./slice/Notification/NotificationUserData";
import GetAlertInterval from "./slice/Notification/GetAlertInterval";
import UpdateNotificationUser from "./slice/Notification/UpdateNotificationUser";
import CanvasJson from "./slice/TPIReport/CanvasJson";
import ElementWiseIssueCount from "./slice/TpiReportSummary/ElementWiseIssueCount";
import IssueWiseCount from "./slice/TpiReportSummary/IssueWiseCount";
import GenerelizedChecklist from "./slice/TpiReportSummary/GenerelizedChecklist";
import SafetyAccessoriesIssueCount from "./slice/TpiReportSummary/SafetyAccessoriesIssueCount";
import TpiIssue from "./slice/TPIReport/TpiIssue";
import SavePdf from "./slice/TPIReport/SavePdf";
import ProjectInformation from "./slice/TPIReport/ProjectInformation";
import ComplianceStandardList from "./slice/TPIReport/ComplianceStandardList";
import TestingCount from "./slice/TpiReportSummary/TestingCount";
import TpiGeneralizedIssueList from "./slice/InspectionDetails/TpiGeneralizedIssueList";
import CycleList from "./slice/Dashboard/CycleList";
import PreviousCycleData from "./slice/Dashboard/PreviousCycleData";
import SplicingIssueCount from "./slice/TpiReportSummary/SplicingIssueCount";
import ReportImagesSummary from "./slice/TPIReport/ReportImagesSummary";
import ResetPassword from "./slice/ResetPassword";
import MDMSettings from "./slice/MDMSettings";
import MergePdf from "./slice/TPIReport/MergePdf";
import CompleteAssessment from "./slice/TPIReport/CompleteAssessment";
import Inventory from "./slice/Sensor/Inventory";
import SensorDetails from "./slice/Sensor/SensorDetails";
import MheSummary from "./slice/Mhe/MheSummary";
import getAverageSpeedByMHE from "./slice/Sensor/getAverageSpeedByMHE";
import AverageSpeedByOperator from "./slice/Sensor/AverageSpeedByOperator";
import OperatorIncidentTrends from "./slice/Sensor/OperatorIncidentTrends";
import TotalEventDistribution from "./slice/Sensor/TotalEventDistribution";
import OverviewStatics from "./slice/Sensor/OverviewStatics";
import OperatorUtilizationTrends from "./slice/Sensor/OperatorUtilizationTrends";
import MHEUtilizationTrends from "./slice/Sensor/MHEUtilizationTrends";
import MHEIncidentTrends from "./slice/Sensor/MHEIncidentTrends";
import IncidentsOverTime from "./slice/Sensor/IncidentsOverTime";
import MHEStatistics from "./slice/Mhe/MHEStatistics";
import AverageUse from "./slice/Mhe/AverageUse";
import MHEUtilization from "./slice/Mhe/MHEUtilization";
import TodaySpeedStatistics from "./slice/Mhe/TodaySpeedStatistics";
import AverageAndPeakSpeedStatistics from "./slice/Mhe/AverageAndPeakSpeedStatistics";
import EventsListByMHE from "./slice/Mhe/EventsListByMHE";
import IssueListForDownload from "./slice/Issues/IssueListForDownload";
import OperatorsStatistics from "./slice/Mhe/Operator/OperatorsStatistics";
import OperatorSummaryList from "./slice/Mhe/Operator/OperatorSummaryList";
import ReportedIssuesDetails from "./slice/Inspection/ReportedIssuesDetails";
import LayoutListForGraph from "./slice/RackHealth/LayoutListForGraph";
import GetWarehouseDetails from "./slice/GetWarehouseDetails";
import WarehouseReport from "./slice/WarehouseReport";

export default (history: any) =>
  combineReducers({
    user: Auth,
    countries: Countries,
    alert: Alert,
    // reportedIssue: reportedIssue,
    inspectorlist: inspectorList,
    cities: Cities,
    users: User,
    Cycle: Cycle,
    Issue: Issues,
    ScheduleData: ScheduleData, 
    companys: company,
    quotations: quotation,
    licences: licence,
    quotelicence: quotelicence,
    sweetAlert: SweetAlert,
    issuedetails: issuedetails,
    dashboard: Dashboard,
    rackhealth: RackHealth,
    dashboardinspectorlist: InspectorList,
    graphinspectorlist: GraphInspectorList,
    graphdata: GraphData,
    detailedgraphdata: DetailedGraphData,
    rackcount: RackCount,
    reportissuess: ReportIssue,
    completeinspectionlist: Inspection,
    completeinspdetails: CompletedInspectionDetails,
    tpifirmlist: TpiFirmList,
    tpiinpsectionlist: TPInspectionSummary,
    tpifirmdetails: TpiFirmDetails,
    qrcodes: QRCodes,
    rackinpsctionstat: RackInspectionStatistics,
    rackhealthcount: RackHealthCount,
    projectlist: ProjectList,
    layoutlist: LayoutList,
    layoutlistgraph: LayoutListForGraph,
    masterlayout: MasterLayout,
    tpielementcount: TpiReportElementCount,
    warehousedetails: WarehouseDetails,
    inspectionstat: InspectionStatistics,
    rackrun: RackRun,
    nodesdata: NodesData,
    beamdata: BeamData,
    report: Report,
    reportIinspectordetails: InspectorDetails,
    setting: InspectionSetting,
    loading: loadingReducer,
    reportcompanydetails: ComapnyDetails,
    elementcount: elementcount,
    reportreportedissue: ReportReportedIssue,
    issueimages: IssueImages,
    detailedinspectorlist: DetailedInspectorList,
    error: ErrorMessage,
    closeissue: CloseIssue,
    testreading: TestReading,
    ElementSummary: ElementSummary,
    observationlist: ObservationList,
    subissuelist: SubIssueList,
    synctpidata: SyncTpiDataToPrses,
    syncreportedissues: SyncReportedIssues,
    rackhealthgraphdata: RackHealthGraphData,
    tpiactionrequired: TpiActionRequired,
    prseselementcount: PrsesElementCount,
    syncrackhealthcount: SyncRackHealthCount,
    syncinspectioncount: SyncInspectionCount,
    syncwarehousedata: SyncWarehouseData,
    projectcount: ProjectCount,
    tpischeduledata: TpiScheduleData,
    tpigraphdata: TpiGraphData,
    projectstatus: ProjectStatus,
    previousaction: PreviousAction,
    WarehouseData: WarehouseData,
    racksystemdata: RackSystemData,
    TechnicalInspectionData: TechnicalInspectionData,
    rackwisehealthdata: RackWiseHealthData,
    FrameWiseHealthData: FrameWiseHealthData,
    tpireportedissues: TpiReportedIssues,
    TestingTestReading: TestingTestReading,
    tpireportedissuedetails: TpiReportedIssueDetails,
    levelstatdata: LevelStatistics,
    tpilayoutlist: TpiLayoutList,
    conclusion: Conclusion,
    generalizeissue: TpiGeneralizedIssues,
    InspectionElementCount: InspectionElementCount,
    syncinspectionstat: SyncInspectionStatistics,
    tpiconclusiondata: TpiConclusionData,
    tpiverticalsummary: TpiVerticalSummary,
    reportedissue: ReportedIssue,
    comparedreportedissues: ComparedReportedIssues,
    SetupVendor: SetupVendor,
    structuralImages: StructuralImages,
    structuralExcel: StructuralExcel,
    changepassword: ChangePassword,
    frameWiseData: FrameWiseData,
    issuelist: IssueList,
    issuechecklist: IssueChecklist,
    testinglist: TestingList,
    testingsummary: TestingSummary,
    testingdetails: TestingDetails,
    dashboardissuecount: IssueCounts,
    notification: Notification,
    impactlist: ImpactList,
    elementwisedamages: TpiElementWiseDamages,
    executionsummary: ExecutionSummary,
    compliancedata: Compliance,
    stabilitycertificatedata: StabilityCertificate,
    maintainancestrategydata: MaintainanceStrategy,
    riskassessmentdata: RiskAssessment,
    annexuredata: Annexure,
    inventorysummarydata: InventorySummary,
    fetchLayoutList: fetchLayoutList,
    racklist: RackList,
    timezone: TimeZone,
    planslist: PlansList,
    coupon: Coupon,
    coupondetails: CouponDetails,
    couponownedwarehousedetails: CouponOwnedBy,
    couponwarehouselist: CouponWarehouseList,
    package: AddPackage,
    palletOverloadingData: OverloadingData,
    riskassessmentchartdata: UpdateRiskAssessmentChart,
    HealthParameterListData: HealthParameterList,
    EditHealthParameter: EditHealthParameter,
    customriskdata: CustomRiskAssessmentChartData,
    effectofevent: EffectOfEvent,
    issuediagnosedata: fetchIssueDiagnoseParameterData,
    notificationcount: NotificationCount,
    subscriptiondetails: SubscriptionDetails,
    creditsystemstatistics: CreditSystemStatistics,
    creditsystemtransaction: CreditSystemTransactions,
    tpicompanylogo: TpiCompanyLogo,
    noseveritycount: NoSeverityCount,
    allcreditpoints: AllCreditPoints,
    remainingcreditpoints: RemainingCreditPoints,
    managementusersummary: ManagementUserSummary,
    warehouselist: WarehouseList,
    warehouselistofusers: UserAssignedWarehouseList,
    managementuserdata: ManagementUserDashboard,
    emailnotificationuser: AddUpdateNotificationUser,
    notificationuserdata: NotificationUserData,
    getalertinterval: GetAlertInterval,
    updatenotificationuser: UpdateNotificationUser,
    canvasjson: CanvasJson,
    elementwiseissuecount: ElementWiseIssueCount,
    issuewisecount: IssueWiseCount,
    generalizedchecklist: GenerelizedChecklist,
    safetycount: SafetyAccessoriesIssueCount,
    tpiissue: TpiIssue,
    savepdf: SavePdf,
    projectinformation: ProjectInformation,
    compliancestandardcode: ComplianceStandardList,
    TestingCount: TestingCount,
    tpigeneralizedissuelist: TpiGeneralizedIssueList,
    cyclelist: CycleList,
    previouscycledata: PreviousCycleData,
    spicingissuecount: SplicingIssueCount,
    imagessummary: ReportImagesSummary,
    resetpassword: ResetPassword,
    userdevicedetails: MDMSettings,
    downloadmergepdf: MergePdf,
    completeassessment: CompleteAssessment,
    inventorydata: Inventory,
    sensordetails: SensorDetails,
    mhesummary: MheSummary,
    AverageSpeedByMhe: getAverageSpeedByMHE,
    operatorspeed: AverageSpeedByOperator,
    OperatorIncidentTrends: OperatorIncidentTrends,
    totaleventdistribution: TotalEventDistribution,
    overviewstats: OverviewStatics,
    opratorutilizationtrends: OperatorUtilizationTrends,
    mheutilizationtrends: MHEUtilizationTrends,
    mheincidenttrends: MHEIncidentTrends,
    incidentsovertime: IncidentsOverTime,
    mhestatistics: MHEStatistics,
    mheaverageuse: AverageUse,
    mheutilization: MHEUtilization,
    todayspeedstatistics: TodaySpeedStatistics,
    averageandpeakspeedstatistics: AverageAndPeakSpeedStatistics,
    eventslistbymhe: EventsListByMHE,
    issuelistfordownload: IssueListForDownload,
    operatorstatistics: OperatorsStatistics,
    operatorsummary: OperatorSummaryList,
    detailedreportedissuedetails: ReportedIssuesDetails,
    editwarehousedetails: GetWarehouseDetails,
    detailedreport: WarehouseReport
  });

export const resetEnhancer =
  (rootReducer: Function) => (state: any, action: any) => {
    if (action.type !== "user/userLogout") return rootReducer(state, action);
    const resetState = rootReducer(undefined, {});
    resetState.router = state.router;
    return resetState;
  };
