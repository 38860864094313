import { DataServer } from "../../../config.axios";
import { createSlice } from "@reduxjs/toolkit";
import { toggleLoading } from "../LoadingSlice";
import decryptData from "../../../reusable/decryptData";
import { removeAll } from "../../../utils/getLocalStorage";

interface LogoutUserState {
    logoutuser: {
        load: boolean;
        data: [];
    };
}

export const logoutUserSlice = createSlice({
    name: "logoutuser",
    initialState: {
        logoutuser: {
            load: false,
            data: [],
        },
    } as LogoutUserState,
    reducers: {},
    // extraReducers: (builder: any) => {
    //     builder
    //         .addCase(LogoutUser.pending, (state: LogoutUserState) => {
    //             state.logoutuser.load = true;
    //         })
    //         .addCase(LogoutUser.fulfilled, (state: LogoutUserState, action: any) => {
    //             state.logoutuser.data = action.payload;
    //             state.logoutuser.load = false;
    //         })
    //         .addCase(LogoutUser.rejected, (state: LogoutUserState) => {
    //             state.logoutuser.load = false;
    //         });
    // },
});

export default logoutUserSlice.reducer;

export const LogoutUser = (navigate?: Function) => async (dispatch: Function) => {
    const loginUserData = decryptData();
  
    if (loginUserData?.token) {
      try {
        dispatch(toggleLoading());
        const { data, status } = await DataServer.post("api/logout");
  
        if (status === 200) {
          if (navigate) {
            removeAll();
            navigate("/");
            window.location.reload();
          }
        }
        // return data;
      } catch (error) {
        // console.log(error)
      }
    }
  };
